import React from 'react'
import './Comment.css'
import urls from '../utils/urls'
import { post } from '../utils/fetch'
import request from '..'
import { RandomAvatar } from 'react-random-avatars'
import { minidenticon } from 'minidenticons'


function CommentUpvoteIcon() {
    return (
        <svg className='comment-detail--upvote-icon' viewBox="0 0 14 12" role="presentation">
            <g><path d="M7.19312 0L0.193115 7H5.19312V12H9.19312V7H14.1931L7.19312 0Z" fill="currentColor"></path></g>
        </svg>
    )
}

function CommentText(props) {
    const comment = props.comment

    const maxLen = 300
    const content = comment.content
    const isLong = content?.length > maxLen
    const [expand, setExpand] = React.useState(false)
    
    function handleClick() {setExpand(prev => !prev)}
    const btnLabel = expand ? 'Show less' : '  ... Read more'

    return (
        <div className='mb-2'>
            <div className='comment-text mb-1'>
                {expand ? content : content.slice(0, maxLen)}
                {(btnLabel === 'Show less') && '\n'}
                {
                    isLong && <div className='comment-read-more-btn d-inline-block link-secondary 
                    cursor-pointer fw-bold' onClick={handleClick}>{btnLabel}</div>
                }
            </div>
        </div>
        
    )
}


// https://www.npmjs.com/package/minidenticons
const MinidenticonImg = ({ username, saturation, lightness, ...props }) => {
    const svgURI = React.useMemo(
      () => 'data:image/svg+xml;utf8,' + encodeURIComponent(minidenticon(username, saturation, lightness)),
      [username, saturation, lightness]
    )
    return (<img className='comment-identicon' src={svgURI} alt={username} {...props} />)
  }


export default function Comment(props) {   
    const comment = props.comment

    const [isVoted, setIsVoted] = React.useState(comment.is_voted)
    const [voteCount, setVoteCount] = React.useState(comment.vote_count)
    const [voteDisabled, setVoteDisabled] = React.useState(false)
    const className = isVoted ? 'comment-detail--upvote-active' : 'comment-detail--upvote'
    
    function submitAction() {
        if (!request.isAuthenticated) {
            window.alert('You need to login to vote')
            return
        }
        if (voteDisabled) {
            return
        }
        const url = `${urls.commentListUrl}${comment.id}/action/`
        setVoteDisabled(true)
        setIsVoted(prev => !prev)
        isVoted ? setVoteCount(prev => prev-1) : setVoteCount(prev => prev+1)

        post(url, {action: isVoted ? 'unvote' : 'vote'})
        .finally(() => setVoteDisabled(false))
    }
    
    return (
        <div className="comment">
            <CommentText comment={comment}/> 
            <div className="comment-detail">
                <div className={className} onClick={submitAction}>
                    <CommentUpvoteIcon />
                    <div className="comment-detail--upvote-count">{voteCount}</div>
                </div>
                <div className='comment-author'>
                    <a 
                    // href={`${profileDetailUrl}${props.author_username}`}
                    className='text-decoration-none d-flex align-items-center mb-1 gap-1'>
                        <div className='pe-1'>{comment.user}</div>
                        {/* <RandomAvatar name={comment.user} size={28} /> */}
                        <MinidenticonImg username={comment.user} saturation="90" />
                    </a>
                </div>
            </div>
        </div>
    )
}
