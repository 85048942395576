import React from "react"
import urls from "../utils/urls"


export default function Footer() {
    let footerClassName = 'footer px-3 py-3 my-4 mb-2 text-center'
    return (
        <div className={footerClassName}>
            <div className='d-flex flex-column align-items-center gap-2'>
                <a className='link-secondary' href={urls.aboutUrl}>About</a>
                <a className='link-secondary' href={urls.contactUrl}>Contact Us</a>
            </div>
        </div>  
    )
}