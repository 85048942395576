const backendBuild = true

const urls = {
  mainUrl: '/',
  aboutUrl: '/about/',
  contactUrl: '/contact/',
  createTopicUrl: '/topic-create/',
  loginUrl: '/login/',
  logoutUrl: '/logout/',
  registerUrl: '/register/',
  profileDetailApi: '/api/profiles/',
  profileDetailUrl: '/profiles/',
  topicListUrl: '/api/topics/',
  topicDetailUrl: '/api/topics/',
  topicCreateUrl: '/api/topics/create/',
  commentListUrl: '/api/comments/',
  commentCreateUrl: '/api/comments/create/',
  optionsUrl: '/api/options/',
  searchUrl: '/api/search/',
  googleLoginUrl: '/accounts/google/login/',
}

if (!backendBuild) {
  for (const [key, value] of Object.entries(urls)) {
    urls[key] = 'http://localhost:8000' + value
  }
}
export default urls