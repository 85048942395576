import csrftoken from "./getCookie"


export default async function cachedFetch(url) {
    const cachedResp = window.sessionStorage.getItem(url)
    if (cachedResp) {
        return JSON.parse(cachedResp)
    }
    
    return (
        await fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error()
            }
            return response
        })
        .then(response => response.json())
        .then(response => {
            window.sessionStorage.setItem(url, JSON.stringify(response))
            return response
        }) 
    )
}


export function post(url, body) {
    return (
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(body),
        })
        .then(response => response.json())
    )
}