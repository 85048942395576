import React from 'react'
import './Option.css'
import Comment from './Comment'
import CreateComment from './CreateComment'
import csrftoken from '../utils/getCookie'
import urls from '../utils/urls'
import request from '../index'
import cachedFetch from '../utils/fetch'
import { post } from '../utils/fetch'
import { progressBar } from '../App'


function OptionLeftHeader(props) {
    const option = props.option
    const voteCount = props.voteCount
    const voteClassName = props.voteClassName
    const submitAction = props.submitAction

    const className = voteClassName + 'position-absolute me-2 option-left-header-vote '
    
    return (
        <div className="option-header pt-1 position-relative mb-2">
            <h6 className="option-title">{option.content}</h6>
            <div className={className} onClick={submitAction}>
                <div className="option-header--vote-count">{voteCount}</div>
                <svg className="option-header--vote-icon" viewBox="0 0 14 12">
                    <g><path d="M7.19312 0L0.193115 7H5.19312V12H9.19312V7H14.1931L7.19312 0Z" fill="currentColor"></path></g>
                </svg>
            </div>
        </div>
    )
}


function OptionRightHeader(props) {
    const option = props.option
    const voteCount = props.voteCount
    const voteClassName = props.voteClassName
    const submitAction = props.submitAction

    const className = voteClassName + 'position-absolute ms-2 option-right-header-vote '
    
    return (
        <div className="option-header pt-1 position-relative mb-2">
            <div className={className} onClick={submitAction}>
                <svg className="option-header--vote-icon" viewBox="0 0 14 12">
                    <g><path d="M7.19312 0L0.193115 7H5.19312V12H9.19312V7H14.1931L7.19312 0Z" fill="currentColor"></path></g>
                </svg>
                <div className="option-header--vote-count">{voteCount}</div>
            </div>
            <h6 className="option-title">{option.content}</h6>
        </div>
    )
}


function OptionHeader(props) {
    const option = props.option
    const index = props.index
    const votes = props.votes

    const [voteDisabled, setVoteDisabled] = React.useState(false)
    const isVoted = votes.options[option.id].isVoted 
    
    function submitAction() {
        if (!request.isAuthenticated) {
            window.alert('You need to login to vote')
            return
        }
        if (voteDisabled) {
            return
        }

        const actionUrl = `${urls.optionsUrl}${option.id}/action/`
        setVoteDisabled(true)
        post(actionUrl, {action: isVoted ? 'unvote' : 'vote'})
        .finally(() => setVoteDisabled(false))

        isVoted ? votes.unvote(option.id) : votes.vote(option.id)
    }

    const voteCount = votes?.options[option.id]?.voteCount
    const voteClassName = isVoted ? 'option-header--vote-active ' : 'option-header--vote '

    return (
        index === 0 
        ?
        <OptionLeftHeader option={option} voteCount={voteCount} 
        voteClassName={voteClassName} submitAction={submitAction}/> 
        :
        <OptionRightHeader option={option} voteCount={voteCount} 
        voteClassName={voteClassName} submitAction={submitAction}/>
    )
}


function CommentList(props) {
    const comments = props.comments

    return (
        <div className='option-comment-list'>
            {
                comments.map((comment, index) =>
                    <Comment 
                    key={comment.id}
                    // key={index}
                    comment={comment} />
                )
            }
        </div>
    )
}

function LoadMoreBtn(props) {
    const loadComments = props.loadComments
    const isMoreLoading = props.isMoreLoading
    const commentsUrl = props.commentsUrl

    let className = 'comment-load-more text-secondary fw-bold btn pt-4 pb-3 border-0 '
    !commentsUrl && (className += 'disabled ')

    return (
        commentsUrl && 
        (
            !isMoreLoading 
            ?
            <div className={className} onClick={() => loadComments()}>Load more...</div> 
            :
            <div className='justify-content-center d-flex pt-3 pb-4'>
                <div className="spinner-border spinner-border text-primary"></div> 
            </div>
        )
    )
}


function SortBy(props) {
    const sortBy = props.sortBy
    const setSortBy = props.setSortBy

    return (
        <div className="comments-sortby d-flex justify-content-end align-items-center">

            <div className="dropdown text-inherit" >
                <button className="w-100 bg-primary btn btn-secondary p-0 bg-transparent border-0 shadow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-filter-left" viewBox="0 0 16 16">
                        <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
                    </svg>
                    <span className='fw-bold'> Sort by</span>
                </button>
                <ul className="dropdown-menu shadow">
                    <li onClick={() => setSortBy('recent')}>
                        <a className={'dropdown-item ' + (sortBy === 'recent' ? 'active' : '')} 
                        href='#'>Most recent</a>
                    </li>
                    <li onClick={() => setSortBy('popular')}>
                        <a className={'dropdown-item ' + (sortBy === 'popular' ? 'active' : '')} 
                        href='#'>Popular</a>
                    </li>
                </ul>
            </div>

        </div>
    )
}


function loadComments_(url, setCommentsUrl, setComments, setIsMoreLoading, reset=false) {
    if (!url) {
        return
    }
    setIsMoreLoading(true)
    cachedFetch(url)
    .then(response => {
        setCommentsUrl(response.next)
        if (!reset) {
            setComments(prev => prev.concat(response.results))
        } else {
            setComments(response.results)
        }
    })
    .finally(() => {
        setIsMoreLoading(false)
        progressBar.finish()
    })
}

export default function Option(props) {
    const option = props.option
    const index = props.index
    const votes = props.votes
    const colDisplay = props.colDisplay

    const [comments, setComments] = React.useState([])
    const [isMoreLoading, setIsMoreLoading] = React.useState(true)
    const initialCommentsUrl = `${urls.optionsUrl}${option.id}/comments/?page=1`
    const [commentsUrl, setCommentsUrl] = React.useState(initialCommentsUrl)
    const [sortBy, setSortBy] = React.useState('recent')
    const hidden = (colDisplay !== null) && (props.index !== colDisplay)
    const columnRef = React.useRef()

    function loadComments(customCommentsUrl, reset=false) { 
        const url = customCommentsUrl || commentsUrl
        loadComments_(url, setCommentsUrl, setComments, setIsMoreLoading, reset)
    }

    React.useEffect(() => {
        const currentWidth = columnRef.current.offsetWidth
        columnRef.current.style.minWidth = `${currentWidth}px`
        !hidden && setTimeout(() => {
            columnRef.current && (columnRef.current.style.minWidth = '')
        }, 0)
    }, [hidden])

    React.useEffect(() => {
        // reset comments and get to page 1 with sortby
        loadComments(`${initialCommentsUrl}&sort=${sortBy}`, true)
    }, [sortBy])

    let className = 'topic-column '
    className += props.index === 0 ? 'topic-left-column ' : 'topic-right-column '
    className += hidden ? 'hidden ' : ''

    return (
        <div className={className} >
            <div className='h-100 d-flex flex-column'
            ref={columnRef}>
                <OptionHeader option={option} votes={votes} index={index} />
                <div className='option-comment-list-container'>
                    <div className='option-comment-list-inner'>
                        <SortBy sortBy={sortBy} setSortBy={setSortBy} />
                        <CreateComment setComments={setComments}
                        option={option} />
                        <CommentList comments={comments} />
                        <LoadMoreBtn isMoreLoading={isMoreLoading} 
                        loadComments={loadComments} commentsUrl={commentsUrl} />
                    </div>    
                </div>
            </div>
        </div>
    )
}