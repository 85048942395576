import React from 'react'
import { Link } from 'react-router-dom'
import './Sidebar.css'
import Footer from './Footer'
import urls from '../utils/urls'
import bp from '../utils/breakpoints'
import cachedFetch from '../utils/fetch'
import { useSearchParams } from 'react-router-dom'
import setSearchParam from '../utils/searchParams'
import ProgressBar from "@badrap/bar-of-progress"


const progressBar = new ProgressBar()


function SidebarListItem(props) {
    const topic = props.topic
    const activeTopicId = props.activeTopicId
    
    const url = `/${setSearchParam('topic', topic.id)}`

    let className = 'text-decoration-none '
    className += 
        parseInt(activeTopicId) === topic.id 
        ? 'sidebar--list-item-active '
        : 'sidebar--list-item '

    return (
        <Link className={className} to={url}>
            <div className="sidebar--topic-title">{topic.title}</div>
            {/* <div className='sidebar-topic-count small text-muted fw-bold'>{props.comment_count}</div>                       */}
        </Link>
    )
}


function Pagination(props) {
    const pagination = props.pagination
    const isLoading = props.isLoading


    const navBtnClass = 'page-link bg-transparent shadow-none ' + (isLoading ? 'disabled ' : '')
    const prevBtnClass = navBtnClass + 'arrow ' + (!pagination.isPrev() ? 'disabled ' : '')
    const nextBtnClass = navBtnClass + 'arrow ' + (!pagination.isNext() ? 'disabled ' : '')

    return (
        <nav className='sidebar-pagination-container user-select-none border-top border-bottom py-4 '>
            <ul className="pagination justify-content-center m-0 fw-bolder">
                <li className="page-item" >
                    <Link className={prevBtnClass} to={pagination.getPrevUrl()} >«</Link>
                </li>
                <li className="page-item">
                    <a className={navBtnClass + 'middle'}>{pagination?.currentPage || 1}</a>
                </li>
                <li className="page-item" onClick={() => !isLoading && pagination.getNextPage()}>
                    <Link className={nextBtnClass} to={pagination.getNextUrl()} >»</Link>
                </li>
            </ul>
        </nav>
    )
}


function TopicList(props) {
    const topicList = props.topicList
    const activeTopicId = props.activeTopicId

    return (
        <div className="sidebar--topic-list">
            {
                topicList?.map((topic) => 
                    <SidebarListItem activeTopicId={activeTopicId} 
                    topic={topic} key={topic.id}/>
                )
            }
        </div>
    )
}


function removeBlurEl() {
    const blurEls = document.getElementsByClassName('blur-element')
    if (!blurEls) {
        return
    }
    for (const blurEl of blurEls) {blurEl.remove()}
    document.getElementById('root').classList.remove('h-100')
}


function createBlurEl(setShowSidebar) {
    const blurEl = document.createElement('div')
    blurEl.setAttribute('class', 'w-100 h-100 position-absolute blur-element') 

    // when blur el is clicked sidebar closes
    blurEl.onclick = () => setShowSidebar(false)

    const mainEl = document.getElementsByClassName('main')[0]
    mainEl.appendChild(blurEl)
    // no scrolling when blur is active
    document.getElementById('root').classList.add('h-100') 
}


class PaginationHandler {
    PAGE_SIZE = 15

    constructor(response, setPagination, currPage) {
        this.response = response
        this.nextUrl = response?.next
        this.prevUrl = response?.previous
        this.pageCount = Math.ceil(response?.count / this.PAGE_SIZE)
        this.setPagination = setPagination
        this.currentPage = currPage || this.getInitialCurrPage()
    }

    getInitialCurrPage() {
        const params = new URLSearchParams(window.location.search)
        return params.get('page') || 1
    }

    getApiUrl(sortBy) {
        let url = `${urls.topicListUrl}?page=${this.currentPage}`
        url += sortBy ? `&sort=${sortBy}` : ''
        return url
    }

    getNextUrl() {
        if (!this.isNext()) {
            return this.getUrl(this.currentPage)
        }
        return this.getUrl(this.currentPage + 1)
    }

    getPrevUrl() {
        if (!this.isPrev()) {
            return this.getUrl(this.currentPage)
        }
        return this.getUrl(this.currentPage - 1)
    }
    
    getUrl(page) {
        return '/' + setSearchParam('page', page)
    }

    getPage(page) {
        page = parseInt(page)
        if (page === this.currentPage) {
            return
        }
        if (!this.setPagination) {
            return // initially it is null
        }
        this.setPagination(
            new PaginationHandler(
                this.response,
                this.setPagination,
                page
            )
        )
    }

    getNextPage() {
        if (this.currentPage === this.pageCount) {
            return
        }
        this.getPage(this.currentPage + 1)
    }

    getPrevPage() {
        if (this.currentPage === 1) {
            return
        }
        this.getPage(this.currentPage - 1)
    }

    isNext() {
        return this.currentPage < this.pageCount
    }

    isPrev() {
        return this.currentPage > 1
    }

}


function scrollToTop(el) {
    el.current.scrollTo({
        top:0,
        // behavior: 'smooth',
    }) 

}


function Head(props) {
    const sortBy = props.sortBy
    const setSortBy = props.setSortBy

    function getUrl(sortBy_) {
        const params = new URLSearchParams(window.location.search)
        params.set('sort', sortBy_)
        // if sortby changes and page is present in the url, page is reset to 1
        if ((sortBy !== sortBy_) && params.get('page')) {
            sortBy !== sortBy_ && params.set('page', 1)
        }
        return `/?${params.toString()}`
    }

    return (
        <div className="sidebar-heading d-flex justify-content-between align-items-center" href="#">

            <span>Topics</span>
        
            <div className="dropdown" >
                <button className="btn btn-secondary p-0 bg-transparent border-0 shadow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-filter-left" viewBox="0 0 16 16">
                        <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
                    </svg>
                </button>
                <ul className="dropdown-menu shadow">
                    <li>
                        <Link className={'dropdown-item ' + (sortBy === 'popular' ? 'active' : '')}
                        to={getUrl('popular')} >Popular</Link>
                    </li>
                    <li>
                        <Link className={'dropdown-item ' + (sortBy === 'trending' ? 'active' : '')}
                        to={getUrl('trending')} >Trending</Link>
                    </li>
                    <li>
                        <Link className={'dropdown-item ' + (sortBy === 'recent' ? 'active' : '')}
                        to={getUrl('recent')} >Most recent</Link>
                    </li>
                </ul>
            </div>

        </div>
    )
}


export default function Sidebar(props) {
    const activeTopicId = props.activeTopicId
    const setActiveTopicId = props.setActiveTopicId
    const showSidebar = props.showSidebar
    const setShowSidebar = props.setShowSidebar
    const showTopicDetail = props.showTopicDetail
    const winSize = props.winSize

    const [topics, setTopics] = React.useState([])
    const [isFloat, setIsFloat] = React.useState(winSize < bp.lg)
    const [searchParams, setSearchParams] = useSearchParams()
    // const [searchParams, setSearchParams] = useSearchParams()
    const [pagination, setPagination] = React.useState(new PaginationHandler())
    const [isLoading, setIsLoading] = React.useState(false)
    const [sortBy, setSortBy] = React.useState(searchParams.get('sort') || 'popular')
    const sidebarEl = React.useRef()

    async function loadTopics() {
        progressBar.start()
        setIsLoading(true)
        return cachedFetch(pagination.getApiUrl(sortBy))
        .then(resp => {setTopics(resp.results); return resp})
        .finally(() => setIsLoading(false))
        .finally(() => progressBar.finish())
    }

    React.useEffect(() => {
        loadTopics()
        .then(resp => setPagination(
            new PaginationHandler(resp, setPagination)
        ))
    }, [])

    React.useEffect(() => {
        pagination.getPage(searchParams.get('page') || 1)
        setSortBy(searchParams.get('sort') || 'popular')
    }, [searchParams])

    React.useEffect(() => {
        loadTopics()
        .then(() => scrollToTop(sidebarEl))
    }, [pagination])

    React.useEffect(() => {
        loadTopics()
    }, [sortBy])

    React.useEffect(() => {
        // sidebar becomes float and default hidden for small size
        setIsFloat(winSize < bp.lg)
        setShowSidebar(winSize > bp.lg)
    }, [winSize])

    React.useEffect(() => {
        // select the first topic if no active topic
        if (topics?.length>0 && showTopicDetail && !activeTopicId) {
            setActiveTopicId(topics[0].id)
        }
    }, [topics, activeTopicId])

    React.useEffect(() => {
        // blur when float sidebar opens
        const shouldBlur = isFloat && showSidebar
        shouldBlur ? createBlurEl(setShowSidebar) : removeBlurEl()
    }, [isFloat, showSidebar])

    let conClassName = isFloat ? 'sidebar-float-container ' : 'sidebar-container '
    !showSidebar && (conClassName += 'hidden ')
    let className = isFloat ?  'sidebar-float ' : 'sidebar '
    !showSidebar && (className += 'hidden ')

    return (
        <div className={conClassName}>
            <div className={className} ref={sidebarEl} id='sidebar'>
                <Head sortBy={sortBy} setSortBy={setSortBy} />
                <TopicList activeTopicId={activeTopicId} topicList={topics} />
                <Pagination pagination={pagination} isLoading={isLoading} />
                <Footer />
            </div>
        </div>
    )
}
