import React from "react";
import './Searchbar.css'
import urls from "../utils/urls";
import axios from "axios";
import { Link } from "react-router-dom";

const searchUrl = urls.searchUrl

function ResultsDropDown(props) {
    const topics = props.topics
    const setShowDropdown = props.setShowDropdown

    return (
        <div className="search-results-dropdown border rounded-4 shadow-sm">
            {   
                topics === null 
                ?
                <div class="search-spinner spinner-border d-block mx-auto" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                :
                topics.length === 0
                ?
                <div className="px-3  text-secondary">Nothing found...</div>
                :
                <div>
                    {  
                        topics.map(topic => 
                            <ResultItem topic={topic} key={topic.id} 
                            setShowDropdown = {setShowDropdown} />
                        )
                    }
                </div>
            }
        </div>
    )
}

function ResultItem(props) {
    const topic = props.topic
    const setShowDropdown = props.setShowDropdown
    
    const url = `/?topic=${topic.id}`

    return (
        <div className="search-result-item">
            <Link to={url} onClick={() => setShowDropdown(false)}
            className="search-result-link d-flex align-items-center gap-3">
                <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" 
                focusable="false" className="" 
                style={{'display': 'block', 'width': '17px', 'height': '100%'}}>
                    <g>
                        <path fill="currentColor" d="M20.87,20.17l-5.59-5.59C16.35,13.35,17,11.75,17,10c0-3.87-3.13-7-7-7s-7,3.13-7,7s3.13,7,7,7c1.75,0,3.35-0.65,4.58-1.71 l5.59,5.59L20.87,20.17z M10,16c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S13.31,16,10,16z"></path>
                    </g>
                </svg>
                {topic.title}
            </Link>
        </div>
    )
}

export default function Searchbar() {
    const [topics, setTopics] = React.useState(null)
    const [query, setQuery] = React.useState('')
    const [cancelToken, setCancelToken] = React.useState(null)
    const [showDropdown, setShowDropdown] = React.useState(false)

    React.useEffect(() => {
        setTopics(null)
        query && setShowDropdown(true)

        const searchTimer = setTimeout(() => {
            if (query) {
                if (cancelToken) {
                    cancelToken.cancel('Cancelled')
                }
                const newCancelToken = axios.CancelToken.source()
                setCancelToken(newCancelToken)
                handleSearch(newCancelToken.token)
            }
        }, 1000)

        return () => {
            clearTimeout(searchTimer)
            if (cancelToken) {
                cancelToken.cancel('Cancelled')
            }
        }

    }, [query])

    const handleSearch = async (cancelToken) => {
        try {
            const url = `${searchUrl}?query=${query}`
            const response = await axios.get(url, {cancelToken: cancelToken})
            setTopics(response.data)
            console.log(response.data)
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request cancelled:', error.message)
            }
        }
    }

    const handleChange = (e) => {
        const query = e.target.value
        setQuery(query)
        query === '' && setTopics(null)
    }

    const handleBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowDropdown(false)
        }
    }

    return (
        <div className="searchbar d-flex align-items-center rounded-pill"
        onBlur={handleBlur} tabIndex={1}
        onFocus={() => query && setShowDropdown(true)}>

            <div className="search-input d-flex">
                <input className="rounded-pill rounded-end" autoCapitalize="none" 
                tabIndex="0" type="text" spellCheck="false" placeholder="Search"
                onChange={handleChange}></input>
            </div>

            <div className="search-btn rounded-pill rounded-start">
                <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" 
                focusable="false" className="mx-auto" 
                style={{'display': 'block', 'width': '20px', 'height': '100%'}}>
                    <g>
                        <path fill="currentColor" d="M20.87,20.17l-5.59-5.59C16.35,13.35,17,11.75,17,10c0-3.87-3.13-7-7-7s-7,3.13-7,7s3.13,7,7,7c1.75,0,3.35-0.65,4.58-1.71 l5.59,5.59L20.87,20.17z M10,16c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S13.31,16,10,16z"></path>
                    </g>
                </svg>
            </div>   
            
            {
                (query && showDropdown) && 
                <ResultsDropDown topics={topics} setShowDropdown = {setShowDropdown} />
            }

        </div>
        
    )
}


