import React from 'react';
import { 
    useLocation, useSearchParams,
    BrowserRouter, Routes, Route 
} from 'react-router-dom';
import ProgressBar from "@badrap/bar-of-progress"
import './App.css';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import TopicDetail from './components/TopicDetail';
import ProfileBadge from './components/ProfileBadge';
import CreateTopic from './components/CreateTopic';
import Register from './components/Register';
import Login from './components/Login';
import urls from './utils/urls';
import bp from './utils/breakpoints';

const progressBar = new ProgressBar()

const aboutEl = document.getElementById('about')
const loginEl = document.getElementById('login')
const logoutEl = document.getElementById('logout')
const registerEl = document.getElementById('register')
const contactEl = document.getElementById('contact')
const topicCreateEl = document.getElementById('topic_create')
const ssrEl = aboutEl || logoutEl || contactEl || topicCreateEl

const topicDetailEl = document.getElementById('topic_detail')
// let initialTopicId = null;
// if (topicDetailEl)
//     initialTopicId = topicDetailEl.dataset.topicId

const profileDetailEl = document.getElementById('profile_detail')
let profileUsername = ''
if (profileDetailEl) {
    profileUsername = profileDetailEl.dataset.username
}

const initialDarkTheme = window.localStorage.getItem('darkTheme') === 'true'


export default function App(props) {
    const [winSize, setWinSize] = React.useState(0)
    const [activeTopicId, setActiveTopicId] = React.useState(null)
    const [showSidebar, setShowSidebar] = React.useState(winSize >= bp.lg)
    const [darkTheme, setDarkTheme] = React.useState(initialDarkTheme)
    const [showCreateTopic, setShowCreateTopic] = React.useState(false)
    const [showTopicDetail, _] = React.useState(topicDetailEl ? true : false)
    const [searchParams, setSearchParams] = useSearchParams()

    const updateSize = () => {setWinSize(window.innerWidth)}

    React.useEffect(() => {
        const topicId_ = parseInt(searchParams.get('topic'))
        topicId_ && setActiveTopicId(topicId_)
    }, [searchParams])

    React.useEffect(() => {
        updateSize()
        window.addEventListener('resize', updateSize)
        return () => {window.removeEventListener('resize', updateSize)}
    }, [])

    React.useEffect(() => {
        const bodyEl = document.getElementsByTagName('body')[0]
        if (darkTheme) {    
            bodyEl.classList.add('dark-theme')
        } else {
            bodyEl.classList.remove('dark-theme')
        }
    }, [darkTheme])

    const ref = React.useRef()
    React.useEffect(() => {
        ssrEl && ref.current.appendChild(ssrEl)
    }, [])

    return (
        <React.Fragment >
            <Navbar winSize={winSize} setShowSidebar={setShowSidebar} setDarkTheme={setDarkTheme}
            setShowCreateTopic={setShowCreateTopic} />
            <div className='main d-flex position-relative'>
                <Sidebar 
                setActiveTopicId={setActiveTopicId} activeTopicId={activeTopicId} 
                winSize={winSize} showSidebar={showSidebar} 
                setShowSidebar={setShowSidebar} showTopicDetail={showTopicDetail} 
                />
                <div className={'content-container h-100 overflow-auto'}>
                    <div className='content'>
                        {
                        activeTopicId 
                        ?
                        <TopicDetail activeTopicId={activeTopicId} 
                        winSize={winSize} />
                        : 
                        profileDetailEl 
                        ?
                        <ProfileBadge username={profileUsername}/>
                        :
                        registerEl || loginEl
                        ?
                        <Routes>
                            <Route path='register' element={<Register htmlEl={registerEl || loginEl} />}></Route>
                            <Route path='login' element={<Login htmlEl={loginEl || registerEl} />}></Route>
                        </Routes>
                        :
                        ssrEl && <div ref={ref} className='m-0 p-0'></div>}   
                    </div>
                    {/* <Footer /> */}
                </div>
            </div>
            <CreateTopic setShowCreateTopic={setShowCreateTopic} 
            showCreateTopic={showCreateTopic} />
        </React.Fragment >
    )
}

export { progressBar }