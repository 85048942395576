import React from 'react'
import './Navbar.css'
import urls from '../utils/urls'
import bp from '../utils/breakpoints'
import request from '..'
import ShareBtn from './Share'
import Searchbar from './Searchbar'



function MenuIcon(props) {
    return (
        <div onClick={props.handleMenuClick}
        className='nav--menu-icon-container d-flex align-items-center m-0 p-0 cursor-pointer'>
            <svg viewBox="0 0 24 24" className="nav--menu-icon p-0 mx-auto">
                <g><path fill='currentColor' d="M21,6H3V5h18V6z M21,11H3v1h18V11z M21,17H3v1h18V17z"></path></g>
            </svg>
        </div>
        
    )
}

function ProfileDropDown(props) {
    return (
        <div className='nav-profile-dropdown position-absolute px-1 py-2 rounded shadow border'>
            {/* <a href={`${urls.profileDetailUrl}${request.username}`} 
            className='text-black text-decoration-none d-block px-2 py-1 rounded'>
                <div>My Profile</div>
            </a> */}
            <a href={urls.logoutUrl} className='text-black text-decoration-none 
            d-block px-2 py-1 rounded'>
                <div>Log out</div>
            </a>
        </div>
    )
}

function Profile(props) {
    const winSize = props.winSize
    const [showMenu, setShowMenu] = React.useState(false)
    const handleProfileClick = () => setShowMenu(prev => !prev)

    function handleBlur(e) {
        if (!e.currentTarget.contains(e.relatedTarget))
            setShowMenu(false)
    }

    return (
        request.isAuthenticated
        ?
        <div className='position-relative user-select-none' tabIndex={1}
        onBlur={handleBlur}>
            <div onClick={handleProfileClick}  
            className='nav-profile-logo text-white fw-bold 
            d-flex align-items-center justify-content-center'>
                {request.username[0]}
            </div>
            {showMenu && <ProfileDropDown setShowMenu={setShowMenu} />}
        </div>
        :
        <div className='nav-signup d-flex align-items-center rounded-pill
        border text-nowrap'>
            <a href={urls.registerUrl} className='text-decoration-none'>
                {winSize >= bp.sm ? 'Sign up / Log in' : 'Sign up'}
            </a>
        </div>
    )
}

function CreateBtn(props) {
    const winSize = props.winSize
    const setShowCreateTopic = props.setShowCreateTopic
    const label = winSize >= bp.lg ? '+ Create a New Topic' :
                  winSize >= bp.md ? '+ New Topic' :
                  '+'
    function handleClick() {setShowCreateTopic(true)}

    return (
        <div className="nav--create-btn rounded-pill border border-primary"
        onClick={handleClick}>
            <div className="text-decoration-none text-white">
                &nbsp;{label}&nbsp;
            </div>
        </div>
    )
}

function ThemeBtn(props) {
    const setDarkTheme = props.setDarkTheme
    function handleClick() {
        setDarkTheme(prev => {
            window.localStorage.setItem('darkTheme', !prev)
            return !prev
        })
    }
    return (
        <div className='nav-theme-btn text-secondary cursor-pointer'
        onClick={handleClick}>
            <svg viewBox="3 0 24 24" preserveAspectRatio="xMidYMid meet" fill='currentColor'
            focusable="false" style={{pointerEvents: 'none', display: 'block', width: '100%', height: '100%'}}>
                <g >
                    <path stroke='currentColor' strokeWidth={0.3}
                    d="M12 22C10.93 22 9.86998 21.83 8.83998 21.48L7.41998 21.01L8.83998 
                    20.54C12.53 19.31 15 15.88 15 12C15 8.12 12.53 4.69 8.83998 3.47L7.41998 
                    2.99L8.83998 2.52C9.86998 2.17 10.93 2 12 2C17.51 2 22 6.49 22 12C22 17.51 
                    17.51 22 12 22ZM10.58 20.89C11.05 20.96 11.53 21 12 21C16.96 21 21 16.96 21 
                    12C21 7.04 16.96 3 12 3C11.53 3 11.05 3.04 10.58 3.11C13.88 4.81 16 8.21 16
                    12C16 15.79 13.88 19.19 10.58 20.89Z" >
                    </path>
                </g>
            </svg>
        </div>
    )
}

export default function Navbar(props) {
    const winSize = props.winSize
    const setShowSidebar = props.setShowSidebar
    const setDarkTheme = props.setDarkTheme
    const setShowCreateTopic = props.setShowCreateTopic
    
    function handleMenuClick() {setShowSidebar(prev => !prev)}
    
    const navPadding = winSize >= bp.md ? 'px-3 ' : 'px-1 '
    const navGap = winSize >= bp.sm ? 'gap-3 ' : 'gap-1 '
    let navClassName = 'nav user-select-none flex-nowrap '
    navClassName += navGap
    navClassName += navPadding
    const leftGap = winSize >= bp.md ? 'gap-3 ' : 'gap-1 '
    const rightGap = winSize >= bp.md ? 'gap-3 ' : 'gap-1 '
    
    return (
        <div id='nav'>
            <nav className={navClassName}>

                <div className={"nav--left align-items-center d-flex " + leftGap}>
                    <MenuIcon handleMenuClick={handleMenuClick} />
                    <div className="nav--title">
                        <a href={urls.mainUrl} className='text-decoration-none'>
                            <span className='gradient-title'>W</span>efute
                        </a>
                    </div>
                    <ShareBtn winSize={winSize} />
                </div>

                <div className='nav-mid'>
                    <Searchbar />
                </div>


                <div className={"nav--right align-items-center d-flex " + rightGap}>
                    <ThemeBtn setDarkTheme={setDarkTheme} />
                    <CreateBtn winSize={winSize} setShowCreateTopic={setShowCreateTopic} />
                    <Profile winSize={winSize} />
                </div>

            </nav>
        </div>
        
    )
}