import React from 'react'
import { progressBar } from '../App'
import Option from './Option'
import './TopicDetail.css'
import urls from '../utils/urls'
import bp from '../utils/breakpoints'
import cachedFetch from '../utils/fetch'


const topicDetailUrl = urls.topicDetailUrl

async function getTopicDetail(topicId) {
   const url = topicDetailUrl + `${topicId}/`
   return cachedFetch(url)
}

// function ColumnDisplayIcon1(props) {
//     const darkTheme = props.darkTheme
//     let className = 'column-display-icon-container cursor-pointer d-flex border px-1 '
//     className += props.position === props.colDisplay ? 
//     'column-display-icon-container-active border ' : ''
//     className += props.position === 0 ? '  rounded-start' : ''
//     className += props.position === 1 ? ' rounded-end' : ''
//     const leftFill = !darkTheme ?
//                     props.position === 1 ? '#a7a7a7' : 'black' :
//                     props.position === 1 ? '#767676' : '#dddddd'
//     const rightFill = !darkTheme ?
//                     props.position === 0 ? '#a7a7a7' : 'black' :
//                     props.position === 0 ? '#767676' : '#dddddd' 
//     const id = props.position + '_col_btn'
//     return (
//         <div id={id} className={className} onClick={props.handleColClick}>
//             <svg className='column-display-icon' viewBox="0 0 24 32">
//                 <path fill={rightFill} d="M16,26c-1.104,0-2-0.896-2-2V8c0-1.104,0.896-2,2-2s2,0.896,2,2v16C18,25.104,17.104,26,16,26z"/>
//                 <path fill={leftFill} d="M8,26c-1.104,0-2-0.896-2-2V8c0-1.104,0.896-2,2-2s2,0.896,2,2v16C10,25.104,9.104,26,8,26z"/>
//             </svg>
//         </div>
//     )
// }


function ColumnDisplayIcon(props) {
    const index = props.index 
    const colDisplay = props.colDisplay
    const setColDisplay = props.setColDisplay

    function handleColClick(e) {
        setColDisplay(prev => prev == index ? null : index)
    }

    let className = 'column-display-icon-container cursor-pointer d-flex border text-success '
    index === colDisplay && (className += 'active ')
    className += index === 0 ? 'rounded-start ' : 'rounded-end '

    return (
        <div className={className} onClick={handleColClick}>
            <svg className='column-display-icon' viewBox="0 0 24 32"  stroke="currentColor" strokeWidth="0.5">
                <path fill='currentColor' d="M12,26c-1.104,0-2-0.896-2-2V8c0-1.104,0.896-2,2-2s2,0.896,2,2v16C14,25.104,13.104,26,12,26z"/>
            </svg>
        </div>
    )
}


function ColumnDisplayNav(props) {
    const winSize = props.winSize
    const colDisplay = props.colDisplay
    const setColDisplay = props.setColDisplay

    const marginEnd = winSize >= bp.sm ? 'me-3' : 'me-1'

    return (
        <div className={'d-flex align-items-center justify-content-end mb-2 \
        column-display-nav-container user-select-none ' + marginEnd}>
            <ColumnDisplayIcon index={0} setColDisplay={setColDisplay}
            colDisplay={colDisplay} />
            <ColumnDisplayIcon index={1} setColDisplay={setColDisplay}
            colDisplay={colDisplay} />
        </div>
    )
}


class Votes {

    constructor(optionSet, setVotes) {
        this.setVotes = setVotes

        const leftOptionId = optionSet[0].id
        const rightOptionId = optionSet[1].id
        this.ids = [leftOptionId, rightOptionId]
        this.leftOption = {
            id: leftOptionId,
            voteCount: optionSet[0].vote_count,
            isVoted: optionSet[0].is_voted,
        }
        this.rightOption = {
            id: rightOptionId,
            voteCount: optionSet[1].vote_count,
            isVoted: optionSet[1].is_voted,
        }
        this.options = {}
        this.options[leftOptionId] = this.leftOption
        this.options[rightOptionId] = this.rightOption

        this.setVotes(this)
    }

    unvote(optionId, isSetVotes=true) {
        const option = this.options[optionId]
        if (!option.isVoted) {
            return
        }
        option.voteCount -= 1
        option.isVoted = false
        isSetVotes && this.setVotes(this.clone())
    }

    vote(optionId) {
        const option = this.options[optionId]
        const otherId = this.ids.filter(id => id !== optionId)[0]
        if (!option.isVoted) {
            option.voteCount += 1
            option.isVoted = true
        }
        this.unvote(otherId, false)
        this.setVotes(this.clone())
    }

    // react doesn't update correctly without new obj
    clone() {
        const optionSet = []
        for (const option of [this.leftOption, this.rightOption]) {
            optionSet.push({
                id: option.id,
                vote_count: option.voteCount,
                is_voted: option.isVoted,
            })
        }
        return new Votes(optionSet, this.setVotes)
    }

}


export default function TopicDetail(props) {
    const activeTopicId = props.activeTopicId
    const winSize = props.winSize

    const [topicDetail, setTopicDetail] = React.useState(false)
    const [votes, setVotes] = React.useState()
    const [colDisplay, setColDisplay] = React.useState(null)
    

    React.useEffect(() =>{
        if (activeTopicId) {
            progressBar.start()
            getTopicDetail(activeTopicId)
            // when topic changes votes stop working because old ids are updated after topic change
            .then(response => {setVotes(null); return response}) 
            .then(response => setTopicDetail(response))
            // .finally(() => progressBar.finish())
        }
    }, [activeTopicId])

    React.useEffect(() => {
        setColDisplay(winSize < bp.sm ? 0 : null)
    }, [winSize])

    React.useEffect(() => {
        topicDetail && new Votes(topicDetail.option_set, setVotes)
    }, [topicDetail])
    

    let className = "topic-detail d-flex flex-column "

    return (
        votes && // topic loads then votes is setup
        <div className={className}>
            <div className='topic-title-container px-1 pb-1 mb-4 mt-0'>
                <ColumnDisplayNav setColDisplay={setColDisplay}
                colDisplay={colDisplay} winSize={winSize} />
                <h5 className="topic-title mx-auto text-center mb-0">
                    {topicDetail.title}
                </h5>
            </div>
            <div className="topic-columns">
                {
                    topicDetail.option_set.map((option, index) => 
                        <Option key={option.id} option={option} index={index} 
                        votes={votes} colDisplay={colDisplay} />
                    )
                }
            </div>
        </div>
    )
}
