import React from "react";
import urls from "../utils/urls";
import csrftoken from "../utils/getCookie";
import { Link } from "react-router-dom";
import './Login.css'
import { parseErrorsEl } from './Register'


export default function Login(props) {
    const htmlEl = props.htmlEl
    const errorsEl = parseErrorsEl(htmlEl)
    
    const ref = React.useRef(null)
    // const googleLoginUrl = htmlEl.dataset.googleLoginUrl

    React.useEffect(() => {
        
        if (ref.current && errorsEl) {
            ref.current.appendChild(errorsEl)
        }
    }, [])

    return (
        <div>
            <div className="social-login-container register ssr-el col-11 col-sm-10 col-md-6 mx-auto">
                <a className="social-login px-4" href={urls.googleLoginUrl}>
                    <svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18"><path fill="#4285F4" d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18Z"></path><path fill="#34A853" d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17Z"></path><path fill="#FBBC05" d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07Z"></path><path fill="#EA4335" d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3Z"></path></svg>
                    Sign in with Google
                </a>
            </div>

            <div ref={ref} id="login" className="login ssr-el col-11 col-sm-10 col-md-6 mx-auto">
                <div className="px-4"> 
                    <form method="POST" action={urls.loginUrl} className="d-flex flex-column gap-2 fw-bold form">
                        <input type="hidden" name="csrfmiddlewaretoken" value={csrftoken}></input>
                        {/* <div id="div_id_username" className="control-group"> 
                            <label for="id_username" className="control-label requiredField">Username<span className="asteriskField">*</span> 
                            </label> 
                            <div className="controls"> 
                            <input type="text" name="username" autoFocus="" className="form-control textinput textInput" placeholder="Username" autoCapitalize="none" autoComplete="username" maxLength="150" required="" id="id_username"></input> 
                            </div> 
                        </div>  */}
                        <div id="div_id_email" className="control-group">
                            <label htmlFor="id_email" className="control-label requiredField">
                                Email<span className="asteriskField">*</span> 
                            </label> 
                            <div className="controls"> 
                                <input type="email" name="login" autoFocus="" className="form-control textinput textInput" required="" id="id_email"></input> 
                            </div> 
                        </div> 
                        <div id="div_id_password" className="control-group"> 
                            <label htmlFor="id_password" className="control-label requiredField">Password<span className="asteriskField">*</span> 
                            </label> 
                            <div className="controls"> 
                                <input type="password" name="password" autoComplete="current-password" className="form-control textinput textInput" placeholder="Password" required="" id="id_password"></input> 
                            </div> 
                        </div>

                        <button type="submit" className="submit-btn btn btn-primary py-2 mx-auto my-4">Login</button>
                    </form>
                </div>
                <div className="register-link text-center mt-2 mb-0">
                    <p><Link to="/register" className="link-secondary">Don't have an account? Register here</Link></p>
                </div>
            </div>
        </div>
    )
}