import React from 'react'
import './CreateComment.css'
import urls from '../utils/urls'
import { post } from '../utils/fetch'


const textareaHeight = '1.75rem'


export default function CreateComment(props) {
    const option = props.option
    const setComments = props.setComments
    
    const [commentText, setCommentText] = React.useState('')
    const [btnHidden, setBtnHidden] = React.useState(true)
    const submitBtnRef = React.createRef()
    const textareaRef = React.createRef()

    React.useEffect(() => {
        submitBtnRef.current.disabled = !Boolean(commentText)
    }, [commentText])

    function handleTextChange(e) {
        setCommentText(textareaRef.current.value)
        textareaRef.current.style.height = textareaHeight
        textareaRef.current.style.height = textareaRef.current.scrollHeight + 2 + 'px'  
    }

    function handleCancelClick(e) {
        e.preventDefault()
        submitBtnRef.current.disabled = true
        setCommentText('')
        setBtnHidden(true)
        textareaRef.current.style.height = textareaHeight
    }
    
    function handleSubmitClick(e) {
        e.preventDefault()
        if (!commentText) {
            return
        }
        submitBtnRef.current.disabled = true
        post(urls.commentCreateUrl, {
            option: option.id,
            content: commentText,
        })
        .then(response => setComments(prev => [response, ...prev]))
        .then(() => handleCancelClick(e))
        .finally(() => submitBtnRef.current.disabled=false)
    }

    return (
        <div className='create-comment'>
            <form className='create-comment--form'>
                <textarea type='text' className='create-comment-text bg-transparent'
                onChange={handleTextChange} placeholder='Add a comment...'
                value={commentText} onFocus={() => setBtnHidden(false)}
                ref={textareaRef} rows={1} spellCheck="false"></textarea>
                <div className='create-comment--bottom' hidden={btnHidden}>
                    <button className='comment-cancel-btn rounded-pill' 
                    onClick={handleCancelClick}>Cancel</button>
                    <button type='submit' className='comment-submit-btn rounded-pill'
                    onClick={handleSubmitClick} ref={submitBtnRef}>Comment</button>
                </div>
            </form>
        </div>
    )
}