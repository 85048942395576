import React from 'react'
import './ProfileBadge.css'
import urls from '../utils/urls'
 

function ProfileInfo(props) {
    const profile = props.profile
    return (
        <div className='ms-3'>
            <div className='d-flex align-items-center gap-2 mb-3'>
                <div className='rounded-circle bg-secondary text-white 
                fw-bold px-3 py-2 fs-6'>
                    {profile.username[0]}
                </div>
                <div className="d-flex flex-column">   
                    <div className='d-flex align-items-center gap-3'>
                        <div className='fs-6 fw-bold d-flex lh-1'>
                            {profile.first_name} {profile.last_name}
                        </div>
                        <small className='text-muted'>
                            {profile.location}  
                        </small>
                    </div>
                    <small className='text-muted d-block lh-1'>@{profile.username}</small>
                </div>
            </div>
            <div className='fw-light'>{profile.bio}</div>
        </div>
    )
}

function ProfileNav(props) {
    const commentsClass = props.navSelection === 'comments' ? 'profile-nav-selected' : null
    const topicsClass = props.navSelection === 'topics' ? 'profile-nav-selected' : null
    const handleCommentsClick = () => props.setNavSelection('comments')
    const handleTopicsClick = () => props.setNavSelection('topics')

    return (
        <div className='ms-2 mt-4 d-flex profile-nav user-select-none'>
            <div className={topicsClass} onClick={handleTopicsClick}>Topics</div>
            <div className={commentsClass} onClick={handleCommentsClick}>Comments</div>
        </div>
    )
}

function TopicElement(props) {
    const [opt1, opt2] = props.option_set
    const url = `${urls.mainUrl}?topic_id=${props.id}`
    return (
        <div className='py-4 px-3 profile-topic-list-item'>
            <div className='fw-bold mb-2 cursor-pointer'>
                <a href={url} className='link-dark'>{props.title}</a>
            </div>
            <div className='d-flex gap-3'>   
                <div className='px-1 py-1 fw-bold bg-secondary text-white px-1 rounded'>{opt1}</div>
                <div className='px-1 py-1 fw-bold bg-secondary text-white px-1 rounded'>{opt2}</div>
            </div>
        </div>
    )
}


function CommentElement(props) {
    const url = `${urls.mainUrl}?topic_id=${props.topic_id}`
    return (
        <div className='py-4 px-3 profile-topic-list-item'>
            <div className='fw-bold mb-2 cursor-pointer'>
                <a href={url} className='link-dark'>{props.topic_title}</a>
            </div>
            <div className='d-flex align-items-center gap-3'>  
                <div className='px-1 py-1 fw-bold bg-secondary d-flex align-items-center text-white px-1 rounded '>{props.option}</div> 
                <div className='px-2 py-2  profile-comment-content'>{props.content}</div> 
            </div>
        </div>
    )
}

function ProfilePostList(props) {
    const posts = props.navSelection === 'topics' ? props.topics : props.comments
    return ( 
        <div className='mt-4'>
            {
            posts && posts.length ?
            posts.map((post, ind) => {
                if (props.navSelection === 'topics') 
                    return <TopicElement key={ind} {...post} />
                else if (props.navSelection === 'comments')
                    return <CommentElement key={ind} {...post} />
            })
            : <div className='py-4 px-3 fw-bold text-muted'>No {props.navSelection}...</div>
            }
        </div>
    )
}

function ProfilePosts(props) {
    const [navSelection, setNavSelection] = React.useState('topics')
    const [topics, setTopics] = React.useState(false)
    const [comments, setComments] = React.useState(false)
    const username = props.profile.username

    function getProfilePosts(username, setPosts, type) {
        const url = `${urls.profileDetailApi}${username}/${type}/`
        fetch(url)
        .then(response => response.json().then(data => {
            return {data: data, status:response.status}
        }))
        .then(response => {
            if (response.status !== 200) 
                throw response
            return response
        })
        .then(response => setPosts(response.data))
        .catch(error => {
            console.log(error)
        })
    }

    React.useEffect(() => {
        const setPosts = navSelection === 'topics' ? setTopics : setComments
        getProfilePosts(username, setPosts, navSelection)
    }, [navSelection])

    return (
        <React.Fragment>
            <ProfileNav navSelection={navSelection} setNavSelection={setNavSelection}/>
            <ProfilePostList topics={topics} comments={comments} navSelection={navSelection}/>
        </React.Fragment>
    )
}

export default function ProfileBadge(props) {
    const [profile, setProfile] = React.useState(false)

    async function getProfile(username) {
        const url = `${urls.profileDetailApi}${username}/`
        return fetch(url)
        .then(response => response.json().then(data => {
            return {data: data,status: response.status} }))
        .then(response => {
            if (response.status !== 200) 
                throw response
            return response.data
        })
        .catch(error => {console.log(error); throw error})
    }

    React.useEffect(() => {
        getProfile(props.username)
        .then(data => setProfile(data))
    }, [])

    return (
        profile ?
        <div className='col-10 col-md-8 mx-auto py-4 px-5 profile-badge d-flex flex-column'>  
            <ProfileInfo profile={profile}/>
            <ProfilePosts profile={profile}/>
        </div>
        : null   
    )
}