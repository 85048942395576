import React from "react";
import './CreateTopic.css'
import urls from "../utils/urls";
import csrftoken from "../utils/getCookie";
import request from "..";

export default function CreateTopic(props) {
    const showCreateTopic = props.showCreateTopic
    const setShowCreateTopic = props.setShowCreateTopic

    const emptyInput = {title: '', opt1: '', opt2: ''}
    const [input, setInput] = React.useState(emptyInput)
    const [message, setMessage] = React.useState(false)

    function reset() {
        setMessage(false)
        setInput(emptyInput)
    }

    function handleClose(e) {
        const blurClicked = e.target.classList.contains('create-topic-container')
        const crossClicked = e.target.classList.contains('create-topic-cross')
        if (blurClicked || crossClicked) {setShowCreateTopic(false)}
        if (crossClicked) {reset()}
    }

    function handleSubmit(e) {
        
    }

    function handleSubmit(e) {
        e.preventDefault()
        if (!request.isAuthenticated) {
            setMessage({msg: 'You need to login to create a topic.', ok: false})
            return
        }
        fetch(urls.topicCreateUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify({
                title: input.title,
                option_set: [{content: input.opt1}, {content: input.opt2}],
            })
        })
        .then(response => {
            if (response.ok) {
                setMessage({msg: 'Your topic has been created', ok: true})
                setInput(emptyInput)
            }
            else {
                setMessage({msg: 'There was an error. Please try again later.', ok: false})
            }
        })
    }

    const messageEl = message ? 
    <div className={'text-center mt-2 ' + (message.ok ? 'text-success' : 'text-danger')}>
        {message.msg}
    </div> : null

    function handleChange(e) {
        const el = e.target
        if (el.name === 'title') { setInput( prev => ({...prev, title: el.value}) ) }
        if (el.name === 'option-one') { setInput( prev => ({...prev, opt1: el.value}) ) }
        if (el.name === 'option-two') { setInput( prev => ({...prev, opt2: el.value}) ) }
    }
    
    return (
        showCreateTopic &&
        <div className="create-topic-container d-flex align-items-center px-1"
        onClick={handleClose}>
            <div className="create-topic bg-white shadow-sm border rounded mx-auto py-5 px-1
            col-12 col-sm-9 col-md-8 col-lg-6 position-relative">
                <form className='d-flex flex-column mx-auto col-11 col-sm-10 col-md-8 col-lg-6
                gap-3' onChange={handleChange} onSubmit={handleSubmit}>
                    <h5 className="text-center text-primary mb-4">Create a New Topic</h5>
                    <label className='form-label'>
                        <span className='ms-1'>Topic Title</span>
                        <textarea rows={1} class="form-control shadow-none" type='text' 
                        name='title' placeholder="Topic title..." required
                        value={input.title}></textarea>
                    </label>
                    <label className='form-label'>
                       <span className='ms-1'>Option One</span>
                        <textarea rows={1} class="form-control shadow-none" type='text' 
                        name='option-one' placeholder="Option one..." required
                        value={input.opt1}></textarea>
                    </label>
                    <label className='form-label'>
                       <span className='ms-1'>Option Two</span>
                        <textarea rows={1} class="form-control shadow-none" type='text' 
                        name='option-two' placeholder="Option two..." required
                        value={input.opt2}></textarea>
                    </label> 
                    <button className='btn btn-primary shadow-none mx-auto py-1 mt-4'>Post</button>
                    {messageEl}
                </form>    
                <div className="position-absolute top-0 end-0 mt-2 me-3 cursor-pointer
                create-topic-cross user-select-none" onClick={handleClose}>&#x2716;</div>            
            </div>
        </div>
    )
}