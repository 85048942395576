import React from 'react'
import {
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
} from "react-share"

import {
    FacebookIcon, 
    LinkedinIcon, 
    PinterestIcon, 
    RedditIcon,
    TelegramIcon, 
    TumblrIcon, 
    TwitterIcon, 
    WhatsappIcon,
    EmailIcon,
} from "react-share";
import bp from '../utils/breakpoints';

function ShareDropDown() {
    const itemClassName = 'nav-share-dropdown-item'
    const url = 'https://wefute.com/'
    const title = 'Join me on discussion and choose your side \n'
    const iconSize = 30
    const borderRadius = 30
    return (
        <div className='nav-share-dropdown position-absolute py-1
        rounded-3 align-items-center shadow border' tabIndex='0'>
            <div className='d-flex flex-column'>
                <div className={itemClassName}>
                    <TwitterShareButton 
                    url={url} 
                    children={<TwitterIcon size={iconSize} borderRadius={borderRadius} />}
                    title={title}
                    />
                </div>

                <div className={itemClassName}>
                    <WhatsappShareButton 
                    url={url} 
                    children={<WhatsappIcon size={iconSize} borderRadius={borderRadius} />}
                    title={title}
                    />
                </div>

                <div className={itemClassName}>
                    <FacebookShareButton 
                    url={url} 
                    children={<FacebookIcon size={iconSize} borderRadius={borderRadius} />}
                    quote={title}
                    />
                </div>

                <div className={itemClassName}>
                    <RedditShareButton 
                    url={url} 
                    children={<RedditIcon size={iconSize} borderRadius={borderRadius} />}
                    title={title}
                    />
                </div>

                <div className={itemClassName}>
                    <TelegramShareButton 
                    url={url} 
                    children={<TelegramIcon size={iconSize} borderRadius={borderRadius} />}
                    title={title}
                    />
                </div>

                <div className={itemClassName}>
                    <LinkedinShareButton 
                    url={url} 
                    children={<LinkedinIcon size={iconSize} borderRadius={borderRadius} />}
                    title={title}
                    />
                </div>

                <div className={itemClassName}>
                    <PinterestShareButton 
                    url={url} 
                    children={<PinterestIcon 
                    size={iconSize} borderRadius={borderRadius} />}
                    description={title}
                    />
                </div>

                <div className={itemClassName}>
                    <TumblrShareButton 
                    url={url} 
                    children={<TumblrIcon size={iconSize} borderRadius={borderRadius} />}
                    title={title}
                    />
                </div>
                
                <div className={itemClassName}>
                    <EmailShareButton 
                    url={url} 
                    children={<EmailIcon size={iconSize} borderRadius={borderRadius} />}
                    title={title}
                    />
                </div>
            </div>
        </div>
    )
}

export default function ShareBtn(props) {
    const [showMenu, setShowMenu] = React.useState(false)
    const handleShareClick = () => setShowMenu(prev => !prev)
    const winSize = props.winSize
    const btnLabel = winSize >= bp.lg ? 'Invite your friends to discussion' : 
                     winSize >= bp.sm ? 'Share' : ''
    const margin = winSize >= bp.md ? 'ms-4' : 
                   winSize >= bp.sm ? 'ms-3' :
                   'ms-1'
    function handleBlur(e) {
        if (!e.currentTarget.contains(e.relatedTarget))
            setShowMenu(false)
    }

    return (
        <div className='position-relative text-secondary' onBlur={handleBlur} tabIndex={1}>
            <div className={margin + 
            ' nav-share-btn d-flex align-items-center gap-1 rounded-pill border '}
            onClick={handleShareClick}>
                <svg className='nav-share-icon' viewBox="0 0 18 20">
                    <g fill="currentColor">
                        <path d="M15 14.08C14.24 14.08 13.56 14.38 13.04 14.85L5.91 10.7C5.96 
                        10.47 6 10.24 6 10C6 9.76 5.96 9.53 5.91 9.3L12.96 5.19C13.5 5.69 14.21 
                        6 15 6C16.66 6 18 4.66 18 3C18 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 
                        3.24 12.04 3.47 12.09 3.7L5.04 7.81C4.5 7.31 3.79 7 3 7C1.34 7 0 8.34 0 10C0 
                        11.66 1.34 13 3 13C3.79 13 4.5 12.69 5.04 12.19L12.16 16.35C12.11 16.56 12.08 
                        16.78 12.08 17C12.08 18.61 13.39 19.92 15 19.92C16.61 19.92 17.92 18.61 17.92
                        17C17.92 15.39 16.61 14.08 15 14.08Z">
                        </path>
                    </g>
                </svg>
                <div className=''>
                    {btnLabel}&#x200B;
                </div>
            </div>
            {showMenu && <ShareDropDown />}
        </div>
    )
}