import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App'
import reportWebVitals from './reportWebVitals';


const rootElement = document.getElementById('root')
const isAuthenticated = rootElement.dataset.isAuthenticated === 'True' ? true : false
const requestUsername = rootElement.dataset.username
const request = {isAuthenticated: isAuthenticated, username: requestUsername}

export default request


if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    // <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    // </React.StrictMode>
  )
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


const pageAccessedByReload = (
  (window.performance.navigation && window.performance.navigation.type === 1) ||
    window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav.type)
      .includes('reload')
);

window.addEventListener('beforeunload', function (event) {
  if (pageAccessedByReload) {
      sessionStorage.clear();
  }
});